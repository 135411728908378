import { useQuery } from '@apollo/client';
import { getFooterPortal } from '@catalogo/service-portal-footer/src/queries/footer.graphql';

export const useFooter = () => {
  const { data } = useQuery(getFooterPortal, {
    variables: {
      sitepage: 'portalb2b/footer',
    },
  });

  const highlightList = data?.publication?.component?.children?.[0]?.children ?? [];
  const moreInfoList = data?.publication?.component?.children?.[1]?.children ?? [];

  return { data, moreInfoList, highlightList };
};
