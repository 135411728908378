import React from 'react';
import compose from 'lodash/fp/compose';
import { withConfig } from '@catalogo/core-connect/config';
import { useFooter } from './hooks/footer';

export const withFooter = Component => props => {
  const { highlightList, moreInfoList } = useFooter();
  return <Component {...props} highlightList={highlightList} moreInfoList={moreInfoList} />;
};

export default compose(withConfig, withFooter);
